/* eslint-disable react/no-danger-with-children */
/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import classHandler from "classnames";
import { svgIcon } from "./index";
import productHandler from "./product-handler";
import { ASSETS_URI } from "../config";
import { toast } from "react-toastify";
import Service from "../Action/service";
import SVGData from "../Common/svg";
import Carousel from "./carousel"
const getHotSpotClass = (data, singlePage) => {
	const { meta } = data;
	const colourTheme = meta.btnTheme || meta.theme;
	const iconSize = meta.size;
	let sizeClass;

	switch (iconSize) {
		case "2":
			sizeClass = "icon-large";
			break;

		case "1":
			sizeClass = "icon-medium";
			break;

		default:
			sizeClass = "icon-small";
			break;
	}

	return classHandler([
		"hot-spot",
		`type-${data.type}`,
		sizeClass,
		{
			//eslint-disable-next-line
			"light-theme": colourTheme === "0",
			"dark-theme": colourTheme === "1",
			"has-icon": meta.type || meta.showBtn !== false,
			"no-icon": !meta.type && meta.showBtn === false,
			"single-page": singlePage,
		},
	]);
};

export default function (pdf, jsonData, events) {
	if (!pdf) return;

	const { slideController, zoomController, popupController } = events || {};
	const { linkData, productData } = jsonData || {};
	const PRODUCT = new productHandler(pdf);

	const uuidv4 = () => {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function (c) {
				//eslint-disable-next-line
				var r = (Math.random() * 16) | 0,
					v = c === "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	};

	const is_iPad = function () {
		return navigator.userAgent.match(/iPad/i) != null;
	};

	const is_touchDevice = function () {
		return "ontouchstart" in window || navigator.maxTouchPoints;
	};

	this.spreadCount = function () {
		let count;
		if (pdf.singlePage) return (count = 1);

		if (window.innerWidth <= 768) count = 1;
		else count = 2;

		if (is_iPad() && window.innerWidth < window.innerHeight) count = 1;
		return count;
	};

	this.sanitizePageNo = function (pageNo) {
		if (!pageNo) return null;
		pageNo = Number(pageNo);

		if (this.spreadCount() > 1) {
			if (pageNo !== 1 && pageNo % 2 !== 0) pageNo = pageNo - 1;
		}

		return pageNo;
	};

	this.getNextPageNo = function (currentPage, pos) {
		const spreadCount = this.spreadCount();
		let newPageNo = pos ? currentPage + spreadCount : currentPage - spreadCount;

		if (currentPage === 1 && spreadCount === 2) newPageNo -= 1;
		if (!pos && newPageNo === 0 && spreadCount === 2) newPageNo = 1;
		if (!pos && newPageNo === 0 && spreadCount === 1) newPageNo = null;

		if (newPageNo > pdf.totalPages && pos) newPageNo = null;
		if (newPageNo < 1 && !pos) newPageNo = null;
		return newPageNo;
	};

	this.getNavigation = function (pageNo, pos) {
		pageNo = this.sanitizePageNo(pageNo);

		if (is_touchDevice() && window.innerWidth <= 768) return null;
		if (pageNo >= pdf.totalPages && pos) return null;
		if (pageNo <= 1 && !pos) return null;
		if (
			pdf.totalPages % 2 !== 0 &&
			this.spreadCount() === 2 &&
			pageNo + 1 >= pdf.totalPages &&
			pos
		)
			return null;

		if (pos) {
			return (
				<div className="navigation right-nav">
					{pageNo !== pdf.totalPages && (
						<span className="nav-end">
							<Link to={`/${pdf.pdfSlug}/page/${pdf.totalPages}`}>
								{svgIcon("ico-arrowEnd")}
							</Link>
						</span>
					)}

					{pageNo !== pdf.totalPages && (
						<span className="nav-navigate">
							<span
								className="link"
								onClick={() =>
									slideController(this.getNextPageNo(pageNo, pos), pos)
								}
							>
								{svgIcon("ico-arrowRight")}
							</span>
						</span>
					)}
				</div>
			);
		} else {
			return (
				<div className="navigation left-nav">
					{pageNo !== 1 && (
						<span className="nav-end">
							<Link to={`/${pdf.pdfSlug}/page/1`}>
								{svgIcon("ico-arrowStart")}
							</Link>
						</span>
					)}

					{pageNo !== 1 && (
						<span className="nav-navigate">
							<span
								className="link"
								onClick={() =>
									slideController(this.getNextPageNo(pageNo, pos), pos)
								}
							>
								{svgIcon("ico-arrowLeft")}
							</span>
						</span>
					)}
				</div>
			);
		}
	};
	this.ScrollSlider = function (
		page,
		searchData,
		zoom = 1,
		handleModal,
		cartCounterHandler,
		singlePage,
		pageview
	) {
		const size = window.innerWidth > 768 ? 0 : 1;
		return (
			<div
				key={page}
				onClick={(e) => this.enableZoom(e, page)}
				className="Scrollsection"
				style={{ minHeight: "100px" }}
				id={"Scrollsection" + page}
			>
				<img
					src={this.getImage(page, size)}
					alt={`Page ${page}`}
					loading="lazy"
				/>
				{this.getHotSpots(
					page,
					searchData,
					handleModal,
					cartCounterHandler,
					singlePage,
					pageview
				)}
			</div>
		);
	};
	this.getSlide = function (
		page,
		searchData,
		zoom = 1,
		handleModal,
		cartCounterHandler,
		singlePage,
		pageview
	) {
		const spreadCount = this.spreadCount();
		const calc = this.getDimension();
		const size = window.innerWidth > 768 ? 0 : 1;
		page = this.sanitizePageNo(page);
		if (page === null) {
			return (
				<div
					className={`slide-item page-cover`}
					key={0}
					style={{ width: `${calc.slider.x * zoom}px` }}
				></div>
			);
		}
		document.getElementsByTagName("meta").namedItem("og:image").content =
			this.getImage(page, size);

		if (spreadCount === 1) {
			return (
				<div
					className={`slide-item page-${page}`}
					style={{ width: `${calc.slider.x * zoom}px` }}
					key={page}
					onClick={this.enableZoom}
				>
					<div className="slide-single">
						<img
							src={this.getImage(page, size)}
							alt={page}
							className="image-item"
						/>
						{this.getHotSpots(
							page,
							searchData,
							handleModal,
							cartCounterHandler,
							singlePage,
							pageview
						)}
					</div>
				</div>
			);
		}

		if (page === 1) {
			return (
				<div
					className={`slide-item page-${page}`}
					style={{ width: `${calc.slider.x * zoom}px` }}
					key={page}
					onClick={this.enableZoom}
				>
					<div className="slide-spread right onlyForFirstPageRight">
						{/* <ZoomImage enableZoom={this.enableZoom}> */}
						<img
							src={this.getImage(page, size)}
							alt={page}
							className="image-item"
						/>
						{this.getHotSpots(
							page,
							searchData,
							handleModal,
							cartCounterHandler,
							singlePage,
							pageview
						)}
						{/* </ZoomImage> */}
					</div>
				</div>
			);
		} else if (page === pdf.totalPages && pdf.totalPages % 2 === 0) {
			return (
				<div
					className={`slide-item page-${page}`}
					style={{ width: `${calc.slider.x * zoom}px` }}
					key={page}
					onClick={this.enableZoom}
				>
					<div className="slide-spread left">
						<img
							src={this.getImage(page, size)}
							alt={page}
							className="image-item"
						/>
						{this.getHotSpots(
							page,
							searchData,
							handleModal,
							cartCounterHandler,
							singlePage,
							pageview
						)}
					</div>
				</div>
			);
		} else {
			return (
				<div
					className={`slide-item page-${page}`}
					style={{ width: `${calc.slider.x * zoom}px` }}
					key={page}
					onClick={this.enableZoom}
				>
					<div
						className="slide-spread left"
						style={{ width: `${(calc.slider.x / 2) * zoom}px` }}
					>
						<img
							src={this.getImage(page, size)}
							alt={page}
							className="image-item"
						/>
						{this.getHotSpots(
							page,
							searchData,
							handleModal,
							cartCounterHandler,
							singlePage,
							pageview
						)}
					</div>
					<div
						className="slide-spread right"
						style={{ width: `${(calc.slider.x / 2) * zoom}px` }}
					>
						<img
							src={this.getImage(page + 1, size)}
							alt={page + 1}
							className="image-item"
						/>
						{this.getHotSpots(
							page + 1,
							searchData,
							handleModal,
							cartCounterHandler,
							singlePage,
							pageview
						)}
					</div>
				</div>
			);
		}
	};

	this.FlipSlide = function (
		page,
		searchData,
		zoom = 1,
		handleModal,
		cartCounterHandler,
		singlePage,
		pageview,
		zindex
	) {
		const spreadCount = this.spreadCount();
		const calc = this.getDimension();
		const size = window.innerWidth > 768 ? 0 : 1;
		page = this.sanitizePageNo(page);
		if (page === null) {
			return (
				<div
					className={`slide-item page-cover`}
					key={0}
					style={{ width: `${calc.slider.x * zoom}px` }}
				></div>
			);
		}
		document.getElementsByTagName("meta").namedItem("og:image").content =
			this.getImage(page, size);

		if (spreadCount === 1) {
			return (
				<div
					className={`slide-item page-${page}`}
					style={{ width: `${calc.slider.x * zoom}px` }}
					key={page}
					onClick={this.enableZoom}
				>
					<div className="slide-single">
						<img
							src={this.getImage(page, size)}
							alt={page}
							className="image-item"
						/>
						{this.getHotSpots(
							page,
							searchData,
							handleModal,
							cartCounterHandler,
							singlePage,
							pageview
						)}
					</div>
				</div>
			);
		}

		if (page === 1) {
			return (
				<div
					id={`page-${page}`}
					className={` page page-${page}`}
					style={{
						width: `${calc.slider.x * zoom}px`,
						height: `${calc.slider.y * zoom}px`,
						zIndex: `${pageview ? 1 : 0}`,
					}}
					onClick={this.enableZoom}
					key={page}
				>
					<div class="default-position left-img left-cover"></div>
					<div class="default-position right-img right-cover">
						<div className="front">
							<img
								src={this.getImage(page, size)}
								alt={page}
								className="image-item right-img"
							/>
							{this.getHotSpots(
								page,
								searchData,
								handleModal,
								cartCounterHandler,
								singlePage,
								pageview
							)}
						</div>
						<div className="back">
							<img
								src={this.getImage(page + 1, size)}
								alt={page}
								className="image-item right-img"
							/>
						</div>
					</div>
				</div>
			);
		} else if (page === pdf.totalPages && pdf.totalPages % 2 === 0) {
			return (
				<div
					id={`page-${page}`}
					className={` page page-${page} `}
					key={page}
					onClick={this.enableZoom}
					style={{
						width: `${calc.slider.x * zoom}px`,
						height: `${calc.slider.y * zoom}px`,
						zIndex: `${pageview ? 1 : 0}`,
					}}
				>
					<div class="default-position left-img left-cover">
						<div className="front">
							<img
								src={this.getImage(page, size)}
								alt={page}
								className="image-item"
							/>
							{this.getHotSpots(
								page,
								searchData,
								handleModal,
								cartCounterHandler,
								singlePage,
								pageview
							)}
						</div>
						<div className="back">
							<img
								src={this.getImage(page - 1, size)}
								alt={page}
								className="image-item"
							/>
						</div>
					</div>
					<div class="default-position right-img right-cover"></div>
				</div>
			);
		} else {
			return (
				<div
					id={`page-${page}`}
					className={`page page-${page}`}
					onClick={this.enableZoom}
					style={{
						width: `${calc.slider.x * zoom}px`,
						height: `${calc.slider.y * zoom}px`,
						zIndex: `${pageview ? 1 : 0}`,
					}}
					key={page}
				// onClick={this.enableZoom}
				>
					<div
						className="slide-spread left left-img"
						style={{ width: `${(calc.slider.x / 2) * zoom}px` }}
					>
						<div className="front">
							{" "}
							<img
								src={this.getImage(page, size)}
								alt={page}
								className="image-item"
							/>
							{this.getHotSpots(
								page,
								searchData,
								handleModal,
								cartCounterHandler,
								singlePage,
								pageview
							)}
						</div>
						<div className="back">
							{" "}
							<img
								src={this.getImage(page - 1, size)}
								alt={page}
								className="image-item"
							/>
						</div>
					</div>
					<div
						className="slide-spread slide-item right-img  right"
						style={{ width: `${(calc.slider.x / 2) * zoom}px` }}
					>
						<div className="front">
							<img
								src={this.getImage(page + 1, size)}
								alt={page + 1}
								className="image-item"
							/>
							{this.getHotSpots(
								page + 1,
								searchData,
								handleModal,
								cartCounterHandler,
								singlePage,
								pageview
							)}
						</div>
						<div className="back">
							{" "}
							<img
								src={this.getImage(page + 1 + 1, size)}
								alt={page + 1}
								className="image-item"
							/>
						</div>
					</div>
				</div>
			);
		}
	};

	this.setDimension = function (calc) {
		this.dimension = calc;
	};

	this.getDimension = function () {
		return this.dimension || {};
	};

	this.getImage = function (pageNo, size = 0, hasPdf = false) {
		let currentPDF = hasPdf || pdf;
		let page = pageNo;
		if (currentPDF.parent && currentPDF.pdfSlug) {
			if (currentPDF.pages[pageNo - 1]) {
				page = parseInt(currentPDF.pages[pageNo - 1]);
			}
		}
		let path = `${ASSETS_URI}/${window.__E_TT_Client.companySlug}/${currentPDF.pdfID}/${page}/image-${size}.jpg`;
		return path;
	};
	this.handleTogglePlay = (id, meta, play = false) => {
		const video = document.getElementById(id);
		const button = document.getElementsByClassName(id + "myBtn")[0];
		if (video.paused || play) {
			button.style.display = "none";
			video.play();
		} else {
			button.style.display = "block";
			video.pause();
		}
	};

	this.forcemute = (id, play = true) => {
		try {
			const video = document.getElementById(id);
			const button = document.getElementsByClassName(id + "mute")[0];

			if (!button) {
				// console.error("Button not found");
				return;
			}

			const image = button.getElementsByTagName("img")[0];

			if (!image) {
				console.error("Image not found inside the button");
				return;
			}
			image.src = play ? "/images/off.svg" : "/images/on.svg";
			video.muted = play;
		} catch (err) {

		}

	};

	this.handlemute = (id) => {
		const video = document.getElementById(id);
		const buttonplay = document.getElementsByClassName(id + "myBtn")[0];
		const button = document.getElementsByClassName(id + "mute")[0];

		if (!button) {
			// console.error("Button not found");
			return;
		}

		const image = button.getElementsByTagName("img")[0];

		if (!image) {
			console.error("Image not found inside the button");
			return;
		}

		if (video.muted) {
			image.src = "/images/on.svg";
			video.muted = false;
		} else {
			image.src = "/images/off.svg";
			video.muted = true;
		}
		if (video.paused) {
			buttonplay.style.display = "none";
			video.play();
		} else {
			buttonplay.style.display = "block";
			video.pause();
		}
	};
	this.getSpotIcon = function (spot) {
		const { showBtn, btnIcon, type } = spot.meta;
		switch (spot.type) {
			case "product":
				//eslint-disbale-next-line
				return type === "0"
					? svgIcon("ico-cart-circle")
					: type === "3"
						? svgIcon("ico-cart-circle", type)
						: svgIcon("ico-download-circle");

			default:
				return showBtn
					? svgIcon(`btn_ico-${btnIcon}`, type, spot.meta?.btn_size_drag)
					: null;
		}
	};
	const handleLoadedMetadata = (event, id, spot) => {
		const videoElement = document.querySelector(`#${id}`);
		// Check if the browser can play the video with audio
		if (!spot?.meta?.Muted) {
			setTimeout(() => {
				this.forcemute(spot?.id, false);
			}, 200);
		}
		this.handleTogglePlay(spot?.id, spot?.meta, true);
		const canPlayAudio =
			videoElement.canPlayType && videoElement.canPlayType("audio/mp4") !== "";
		if (!canPlayAudio) {
			const button = document.querySelector(`.${id}mute`);
			if (button) {
				button.style.display = "none";
			}
		}

	};

	this.getSpotAction = function (spot, pageview) {
		if (!spot || !spot.type) return;
		switch (spot.type) {
			case "text":
				return (
					<div
						style={{ color: "#fff" }}
						children={this.getSpotIcon(spot)}
						dangerouslySetInnerHTML={{ __html: spot.meta.textOption }}
					></div>
				);

			case "pageLink":
				let url = `/${pdf.pdfSlug}/page/${spot.meta.page_no}`;
				if (pdf.verticalScroll && window.innerWidth < 768) {
					url = `/${pdf.pdfSlug}/page/${spot.meta.page_no}?scroll=true`;
				}
				return (
					<Link to={url} children={this.getSpotIcon(spot)} />
				);

			case "link":
				//eslint-disable-next-line
				return (
					<a
						href={spot.meta.link}
						target="_blank"
						rel="noopener noreferrer"
						children={this.getSpotIcon(spot)}
						className={`${!spot?.meta?.showBtn && "blinkTextOnNoIcon"}`}
					/>
				);

			case "video":
				let srcUri = spot.meta.link;
				if (
					srcUri?.search("youtube.com") !== -1 &&
					srcUri?.search("watch?v=") === -1
				) {
					const videoId = spot.meta.link.split("=")[1];
					srcUri = spot.meta.link.replace("watch?v=", "embed/");
					// srcUri = srcUri + `?loop=1&playlist=${videoId}`;
					srcUri =
						srcUri +
						`?loop=1&playlist=${videoId}&controls=0&autoplay=${spot.meta.Autoplay !== undefined && spot?.meta?.Autoplay ? 1 : 0
						}&frameborder=0&showinfo=0&`;
				}
				// setTimeout(() => {
				// 	const elementId = `e-viewer`; // Construct the ID
				// 	const elements = document.getElementsByClassName(elementId); // Get the elements by class name
				// 	if (elements.length > 0) {
				// 		elements[0].click(); // Trigger the click event on the first element
				// 	} else {
				// 		console.error(`Element with class name "${elementId}" not found.`);
				// 	}
				// }, 100);
				return (
					<>
						{spot?.meta?.type === "2" ? (
							<div className="videoContainer">
								{
									pageview && (<video
										id={spot?.id}
										autoPlay={
											spot.meta.Autoplay !== undefined
												? spot?.meta?.Autoplay
												: false
										}
										loop
										// muted={
										// spot.meta.Muted !== undefined ? spot?.meta?.Muted : false
										// }
										pageview={pageview}
										muted={true}
										playsInline
										className="bgVideoPlayer"
										src={spot?.meta?.link}
										controlsList="nodownload"
										onContextMenu={() => false}
										onError={(e) => {
											setTimeout(() => {
												var url = e.target.src;
												const urlParams = new URLSearchParams(url);
												if (spot?.meta?.link && !urlParams.has("t")) {
													e.target.src = spot?.meta?.link + "?t=" + new Date();
												}
											}, 200);
										}}
										onLoadedMetadata={(event) =>
											handleLoadedMetadata(event, spot?.id, spot)
										}
									/>)
								}

								<div
									className={`${spot?.id + "myBtn"} `}
									id={`myBtn`}
									style={{
										display: `${spot.meta.Autoplay !== undefined
											? spot?.meta?.Autoplay
												? "none"
												: " block"
											: "block"
											}`,
									}}
								>
									{SVGData.play}
								</div>
								<div
									className={`${spot?.id + "mute"} `}
									id={`myBtn2`}
									onClick={() => this.handlemute(spot?.id)}
								>
									{spot?.meta?.Muted ? (
										<img src="/images/off.svg" alt="play" />
									) : (
										<img src="/images/on.svg" alt="play" />
									)}
								</div>
							</div>
						) : (
							// <video
							// autoPlay
							// loop
							// muted
							// playsInline
							// // controls
							// className="bgVideoPlayer"
							// src={spot?.meta?.link}
							// controlsList="nodownload"
							// onContextMenu={() => false}
							// />
							// <div
							// className="videoContainer"
							// style={{
							// background: `url(${spot?.meta?.link}) center fixed`,
							// }}
							// />
							<iframe
								src={srcUri}
								// src={srcUri+'&mute=1'}
								frameBorder="0"
								allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								title="video"
								style={{ width: "100%", height: "100%" }}
							/>
						)}
					</>
				);
			case "product":
			case "iframe":
				return <span children={this.getSpotIcon(spot)} />;

			case "addProductDetails":
				return (
					<div
						className={` ${spot.meta.showBtn ? "nohoverdiv" : "hoverdiv"
							} Centerbuttondiv`}
					>
						<div
							className={` ${spot.meta.showBtn ? "notshowonhover" : "showonhover"
								} Centerbuttondiv`}
						>
							{spot.meta.buttontype === 1 && (
								<img
									className="product-image"
									src="/images/ProductButton.svg"
									alt=".."
								/>
							)}
							{spot.meta.buttontype === 0 && (
								<button className="moreinfobutton">More info</button>
							)}
							{spot.meta.buttontype !== 0 && spot.meta.buttontype !== 1 && (
								<div className="arrow_box">
									<h6>Tool Tip Box</h6>
								</div>
							)}
						</div>
					</div>
					// <div className="Centerbuttondiv">
					// <div className="Centerbuttondiv" style={{ position: "relative" }}>
					// <img
					// onClick={productdetail({
					// data: spot.meta.link,
					// isActive: true,
					// type: "iframe",
					// })}
					// className="product-image"
					// src="/images/ProductButton.svg"
					// alt=".."
					// />
					// <div className="white-box">
					// <div>
					// <div>
					// <img src="" alt="..." />
					// </div>
					// <div>
					// <h5>Chicago</h5>
					// <span>{`(${"RA801D12003"})`}</span>
					// <span>Inventory : 150000</span>
					// </div>
					// </div>
					// <div>
					// <span>
					// Slim soft-closing and quick removal seat and cover for nsij xnasixasn...Read more
					// </span>
					// </div>
					// <div></div>
					// </div>
					// </div>
					// </div>
				);

			case "carousel":

				if (spot.meta.view && spot.meta.view === 1) {
					return <></>
				}
				return (
					<Carousel images={[{
						src: "/images/carousal/image3.jpg",
						label: "First Title",
						discription: ""
					},
					{
						src: "/images/carousal/image1.jpg",
						discription: ""
					},
					{
						src: "/images/carousal/image6.jpg",
						discription: ""
					},
					{
						src: "/images/carousal/image7.jpg",
						label: "Last Title",
						discription: ""
					}]} spot={spot} />
				)

			default:
				return null;
		}
	};

	this.getSpots = function (
		spots = [],
		filter = [],
		handleModal,
		cartCounterHandler,
		singlePage,
		pageview
	) {
		if (!spots || spots.length <= 0) return null;
		let jsxSpots = [];

		for (let s in spots) {
			let data = spots[s];
			// if (filter.includes(data.type)) return;

			let divStyle = {
				width: `${data.node.w + 0.05}${data.type === "product" ? "px" : "%"}`,
				height: `${data.node.h}${data.type === "product" ? "px" : "%"}`,
				top: `${data.node.y}%`,
				left: `${data.node.x}%`,
				transform: `${data.type === "product" ? "translateY(20px)" : ""}`,
			};
			jsxSpots.push(
				<span
					className={
						getHotSpotClass(data, singlePage) + (data.type !== "video" && pageview ? " highlight" : "")
					}
					style={divStyle}
					key={data.id || data.uid}
					title={data.meta.showHoverText ? data.meta.title : null}
					children={this.getSpotAction(data, pageview)}
					onClick={(event) => {
						data?.sku_code
							? this.handleSpotClick(
								event,
								data,
								handleModal,
								cartCounterHandler,
								data?.sku_code[0]
							)
							: this.handleSpotClick(
								event,
								data,
								handleModal,
								cartCounterHandler
							);
					}}
				/>
			);
		}

		return jsxSpots;
	};

	this.getHotSpots = function (
		pageNo,
		searchData,
		handleModal,
		cartCounterHandler,
		singlePage,
		pageview
	) {
		pageNo = pdf.parent ? parseInt(pdf.pages[pageNo - 1]) : pageNo;
		let spotData = linkData[pageNo] || {};
		let pageSearch =
			searchData && !Array.isArray(searchData) ? searchData[pageNo] || [] : [];
		// let pageSearch = [];
		let pageProduct = productData ? productData[pageNo] || [] : [];

		pageProduct = _.mapKeys(pageProduct, (p) => p.uid);
		pageSearch = pageSearch.map((s) => ({
			type: "search",
			id: uuidv4(),
			meta: {},
			node: {
				h: s.height,
				w: s.width,
				x: s.x,
				y: s.y,
			},
		}));
		spotData = { ...spotData, ...pageSearch, ...pageProduct };
		return (
			<div
				className="hot-area"
				children={this.getSpots(
					spotData,
					["pageLink"],
					handleModal,
					cartCounterHandler,
					singlePage,
					pageview
				)}
			/>
		);
	};

	this.enableZoom = function (e, page) {
		zoomController(e, page);

	};

	this.handleSpotClick = async (
		e,
		spot,
		handleModal,
		cartCounterHandler,
		SkuCODE
	) => {
		if (!spot) return;
		e.preventDefault();
		e.stopPropagation();
		switch (spot.type) {
			case "video":
				this.handleTogglePlay(spot?.id, spot?.meta);
				break;
			case "product":
				//eslint-disable-next-line
				if (spot.meta.type == "0") {
					if (spot.sku_code.length === 1 || SkuCODE) {
						let com_slug = window.__E_TT_Client.companySlug;
						const res = await Service.post(`public/getallinfobysku`, {
							sku_code: spot.sku_code[0],
							com_slug,
						}).catch((error) => {
						});
						if (res?.data?.data?.child_sku?.length > 0) {
							handleModal(spot, cartCounterHandler);
						} else {
							_.throttle(this.addToCart, 1200)(spot, cartCounterHandler);
						}
					} else {
						handleModal(spot, cartCounterHandler);
					}
				} else if (spot.meta.type === "3") {
					handleModal(spot, cartCounterHandler);
				} else if (spot.meta.type === "1") {
					let _downloadableFiles = [];

					for (let i = 0; i < spot.sku_code.length; i++) {
						let _files = await _.throttle(
							this.showDownloadables,
							1200
						)(spot.sku_code[i]);
						_downloadableFiles.push(_files);
					}

					popupController({
						data: _downloadableFiles,
						isActive: true,
						type: "downloadable",
					});
				}

				break;

			case "iframe":
				spot.meta.playbackvideo === "2"
					? window.open(spot.meta.link, "_blank")
					: popupController({
						data: spot.meta.link,
						isActive: true,
						type: "iframe",
					});
				break;

			case "link":
				window.open(spot.meta.link);
				break;

			case "addProductDetails":
				popupController({
					pdf: pdf,
					data: spot.meta,
					isActive: true,
					type: "addProductDetails",
				});
				break;
			case "carousel":
				if (spot.meta.view === 0) {
					return <></>
				}
				popupController({
					pdf: pdf,
					data: spot.meta,
					spot: spot,
					isActive: true,
					type: "carousel",
				});
				break;
			default:
				break;
		}
	};

	this.addToCart = async (productData, cartCounterHandler) => {
		try {
			const res = await PRODUCT.AddToCart(productData.sku_code);
			const error = res.data.error;

			if (error) {
				toast.success("something went wrong!", {
					className: "toast-style",
				});
			} else {
				toast.success(productData.product_name + " successfully added!", {
					className: "toast-style",
				});

				cartCounterHandler.fetchCartCounterData();
			}
		} catch (error) {
			toast.success("something went wrong!", {
				className: "toast-style",
			});
		}
	};

	this.showDownloadables = async (sku_code) => {
		const { data } = await PRODUCT.GetDownloadable(sku_code);

		return data;

		// if (data && data.things_download) {
		// popupController({
		// data: data.things_download,
		// isActive: true,
		// type: "downloadable"
		// })
		// }
	};

	// this.showDownloadables = async (productData) => {
	// const { data } = await PRODUCT.GetDownloadable(productData.sku_code);
	// if (data && data.things_download) {
	// popupController({
	// data: data.things_download,
	// isActive: true,
	// type: "downloadable"
	// })
	// }
	// }
}
